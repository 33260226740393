<template>
  <form @submit.prevent="confirmSetup">
    <modal-card :title="`Add payment method`" :processing="isProcessing">
      <loading v-if="isLoading" />
      <div v-show="!isLoading" ref="card" />

      <template #footer>
        <b-button
          :loading="isProcessing"
          :disabled="isLoading || isProcessing"
          native-type="submit"
          type="is-success"
          >Add card</b-button
        >
      </template>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "NewCardModal",
  props: {
    userId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      isLoading: true,
      isProcessing: false,
      stripe: null,
      elements: null,
      card: null
    };
  },

  created() {
    this.beginSetup();
  },

  methods: {
    async beginSetup() {
      try {
        const { data } = await this.$store.dispatch("billing/beginSetup");
        // eslint-disable-next-line no-undef
        this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
        this.elements = this.stripe.elements({
          clientSecret: data.client_secret
        });

        // Create and mount the Payment Element
        const paymentElement = this.elements.create("payment");
        paymentElement.mount(this.$refs.card);
        paymentElement.on("ready", () => {
          this.isLoading = false;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async confirmSetup() {
      this.isProcessing = true;
      const { error, setupIntent } = await this.stripe.confirmSetup({
        elements: this.elements,
        redirect: "if_required",
        confirmParams: {
          return_url: window.location.href
        }
      });

      if (setupIntent) {
        this.isProcessing = false;
        return this.$emit("success", setupIntent);
      }

      if (error) {
        this.isProcessing = false;
        return this.$snackbar.open({
          message: error.message,
          position: "is-bottom",
          type: "is-danger",
          duration: 10000,
          queue: false
        });
      }
    }
  }
};
</script>
